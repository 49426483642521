const envVar = process.env.REACT_APP_CUSTOM_ENV
const env = envVar === "production" ? "" : envVar === 'test' ? "Test" : envVar === 'sb-rtt' ? 'Demo' : "Dev";

export const appName = "Real Time Transcription " + env;
export const agentRole = appName + " Agent";
export const supervisorRole = appName + " Supervisor";
export const adminRole = appName + " Admin";

const provisioningInfo = {
    roles: [
        {
            name: appName,
            description: 'Generated role that is used by the application backend',
            permissionPolicies: [
                {
                    "domain": "directory",
                    "entityName": "user",
                    "actionSet": ["*"],
                    "allowConditions": false
                },
                {
                    "domain": "directory",
                    "entityName": "user",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "integrations",
                    "entityName": "integration",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "analytics",
                    "entityName": "conversationDetail",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "analytics",
                    "entityName": "agentConversationDetail",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "authorization",
                    "entityName": "grant",
                    "actionSet": ["view"],
                    "allowConditions": false
                }]
        },
        {
            name: agentRole,
            description: 'Generated role for User access',
            permissionPolicies: [
                {
                    "domain": "routing",
                    "entityName": "queue",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "conversation",
                    "entityName": "communication",
                    "actionSet": ["view"],
                    "allowConditions": false
                },{
                    "domain": "conversation",
                    "entityName": "transcription",
                    "actionSet": ["view"],
                    "allowConditions": false
                },{
                    "domain": "analytics",
                    "entityName": "conversationDetail",
                    "actionSet": ["view"],
                    "allowConditions": false
                }]
        },
        {
            name: supervisorRole,
            description: 'Generated role for Supervisor access',
            permissionPolicies: [
                {
                    "domain": "routing",
                    "entityName": "queue",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "conversation",
                    "entityName": "communication",
                    "actionSet": ["view"],
                    "allowConditions": false
                }]
        },
        {
            name: adminRole,
            description: 'Generated role for Admin access',
            permissionPolicies: [
                {
                    "domain": "routing",
                    "entityName": "queue",
                    "actionSet": ["view"],
                    "allowConditions": false
                },
                {
                    "domain": "conversation",
                    "entityName": "communication",
                    "actionSet": ["view"],
                    "allowConditions": false
                }]
        }
    ],
    oauth: {
        name: appName + ' OAuth',
        description: `Generated OAuth Client that is used by the application backend`,
        authorizedGrantType: 'CLIENT_CREDENTIALS'
    }
};

const development = {
    // backendEndpoint: 'http://localhost:3001/dev/',
    backendEndpoint: 'https://5oychnmh2h.execute-api.us-east-1.amazonaws.com/dev/',
    clientId: 'edc03b19-02cb-4deb-9845-4183d0375fa3',
    appName: appName,
    // appBaseUrl: 'http://localhost:3000/',
    appBaseUrl: 'https://d1ptqlevg3ql42.cloudfront.net/',
    integrationType: "embedded-client-app",
    defaultPcEnv: "mypurecloud.com",
    //provisioningInfo: provisioningInfo,
    permissionPolicyEntityName: "examplePremiumApp",
    provisioningInfo: provisioningInfo,
    pageSize: 100
}



const production = {
    product: 'purecloud',
    backendEndpoint: 'https://6kwiag6o46.execute-api.us-east-1.amazonaws.com/prod/',
    appName: appName,
    appBaseUrl: "https://rtt.genesysinnoapps.com/",
    integrationType: "embedded-client-app",
    permissionPolicyEntityName: "examplePremiumApp",
    defaultPcEnv: 'mypurecloud.com',
    defaultLangTag: "en-us",
    provisioningInfo: provisioningInfo,
    clientId: 'edc03b19-02cb-4deb-9845-4183d0375fa3'
}
const sandbox = {
    backendEndpoint: 'https://5bwmfd7jol.execute-api.us-east-1.amazonaws.com/sb-rtt/',
    clientId: 'edc03b19-02cb-4deb-9845-4183d0375fa3',
    appName: appName,
    appBaseUrl: 'https://d2g3710zgtnmti.cloudfront.net/',
    integrationType: "embedded-client-app",
    defaultPcEnv: "mypurecloud.com",
    //provisioningInfo: provisioningInfo,
    permissionPolicyEntityName: "examplePremiumApp",
    provisioningInfo: provisioningInfo,
    pageSize: 100
}

console.log('env:', env)
let config
switch (envVar) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'sb-rtt': config = sandbox; break;
    default: break;
}

export default config
export const headerTitle = "Genesys Template"