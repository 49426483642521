import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import MessageIcon from '@mui/icons-material/Message';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';

import { StyledSearch, StyledTableCell, StyledTableRow, TableRowQueue } from './common';
import { Box, Checkbox, Divider, Input, InputAdornment, Typography } from '@mui/material';
import TranscriptModal from 'components/transcriptModal/transcriptModal';
import TablePagination from '@mui/material/TablePagination';


import { divisionsList, formatDateAndTime, getSentimentIcon, queuesList } from 'services/utils';
import { getStorageItem } from 'services/applicationStorage';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { getAllConversationDetails, updateFlagStatus } from 'services/purecloud';
import Spinner from 'components/Spinner/Spinner';

import './supervisor.css'
import { QueueContainer, StyledButton } from 'common/common';
import { useNavigate } from 'react-router';
import { InvalidToken } from 'common/Constants';

const SupervisourView = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [openedConversation, setOpenedConversation] = useState(null);
    const [selectedConversations, setSelectedConversations] = React.useState([]);

    const [filteredRows, setFilteredRows] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [filteredQueues, setFilteredQueues] = useState([]);
    const [queues, setQueues] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [isQueueFilter, setQueueFilter] = useState(false);
    const [queueSearch, setQueueSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isFlagAll, setFlagAll] = useState(true);
    // const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);
    const navigate = useNavigate();

    const [apiInterval1, setApiInterval1] = useState();
    const [timerInterval1, setTimerInterval1] = useState();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setSelectedConversations([]);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setSelectedConversations([]);
    };

   

    useEffect(() => {
        const resultList = agentList?.filter((row) => {
            return JSON.stringify(row).toLowerCase().includes(searchValue.toLowerCase().trim());
        });
        setFilteredRows(resultList);

        //remove disappeared conversations
        setSelectedConversations(selectedConversations.filter(item => {
            return agentList.findIndex(conv => conv.id == item.id) !== -1;
        }));

    }, [agentList]);


    const getAllConversations = async () => {
        let requestObj = {
            "divisionIds": Array.from(divisionsList),
            "userId": getStorageItem('userId', true, sessionStorage)
        }
        if (selectedQueue && selectedQueue?.id && selectedQueue?.id !== '') {
            requestObj.selectedQueue = {
                "queueId": selectedQueue.id,
                "divisionId": selectedQueue.divisionId
            }

        }
        try {
          
            const conversationList = await getAllConversationDetails(requestObj);
            setIsLoading(false);


            const queueLiveList = conversationList.data.queueList;

            let initialQueues = [];
            if (queues.length == 0) {
                initialQueues = queuesList;
            } else {
                initialQueues = queues;
            }

            const resultlist = initialQueues.map((item) => {
                return { ...item, liveCount: queueLiveList?.[item.id]?.liveCount, negScoreCnt: queueLiveList?.[item.id]?.negScoreCnt };
            });
            setQueues(resultlist);
            if (selectedQueue && selectedQueue?.id && selectedQueue?.id !== '') {
                setAgentList(conversationList.data.selectedQueue.items);
            }
            console.log("test api")
            return;
        } catch (e) {
            console.error(e);
            if (e.message === InvalidToken) {
                navigate('/login');
                clearInterval(apiInterval);
            }
        }
    }

    useEffect(()=>{
        setQueues(queuesList);
    },[])

    useEffect(() => {
        let timer = 0;
        let isRefreshNeeded = false;
        let timerInterval;
        let apiInterval ;
        setSelectedConversations([]);
        setAgentList([]);
        setIsLoading(true)
        const onRefresh = () => {
            getAllConversations();
            clearInterval(timerInterval);
            clearInterval(apiInterval);
            // apiInterval = setInterval(() => { getAllConversations(); }, 30 * 1000); // in mili seconds
            // setApiInterval1(apiInterval);
            timerInterval = setInterval(() => {
                timer++;
                console.log(timer);
                if(timer%30 === 0) {
                    getAllConversations();
                }
                if (timer === (5 * 60)) {// timer in seconds
                    isRefreshNeeded = true;
                    clearInterval(timerInterval);
                    clearInterval(apiInterval);
                }
            }, 1000);
            // setTimerInterval1(timerInterval);
        }
        onRefresh();
        function onPageActive() {
            timer = 0;
            if (isRefreshNeeded) {
                // setIsRefreshNeeded(false);
                isRefreshNeeded = false;
                onRefresh();
            }
        }
        let ele = document.getElementById("rtt-supervisor-page");

        ele.addEventListener("mouseover", onPageActive);
        ele.addEventListener("keydown", onPageActive);

        const onBlur = ()=> {
            clearInterval(timerInterval);
            clearInterval(apiInterval);
            isRefreshNeeded = true;
            console.log('on blurr');
            // ele.removeEventListener("mousemove", onPageActive);
            // ele.removeEventListener("keydown", onPageActive);
        };
        window.addEventListener('blur',onBlur);
        // const onFocus = ()=> {
        //     onRefresh();
        //     ele.addEventListener("mouseover", onPageActive);
        //     ele.addEventListener("keydown", onPageActive);
        // }
        // window.addEventListener('focus',onFocus);

        return () => {
            clearInterval(timerInterval);
            clearInterval(apiInterval);
            ele.removeEventListener("mousemove", onPageActive);
            ele.removeEventListener("keydown", onPageActive);
            window.removeEventListener('blur', onBlur);
            // window.removeEventListener('focus', onFocus);
            setAgentList([]);
        }
    }, [selectedQueue]);

    useEffect(() => {
        let isFlaggable = false;
        if (selectedConversations.length == 0) {
            setFlagAll(true);
            return;
        }
        for (let i = 0; i < selectedConversations.length; i++) {
            if (!selectedConversations[i].isFlagged) {
                isFlaggable = true;
                break;
            }
        };

        setFlagAll(isFlaggable)
    }, [selectedConversations])

    const onClosePopup = () => {
        setOpenPopup(false);
        setOpenedConversation(null);
    }

    const onOpenPopup = (row) => {
        setOpenedConversation({
            conversationId: row.id,
            isFlagged: row.isFlagged
        });
        setOpenPopup(true);
    }

    const openInGenesysCloud = (conversationId) => {
        const env = getStorageItem("purecloud-csp-env", true, sessionStorage)
        const url = "https://apps." + env + "/directory/#/engage/admin/interactions/" + conversationId;
        window.open(url, '_blank')
    }

    const queueFilter = (filterValue) => {
        setQueueSearch(filterValue?.toLowerCase());
        const filteredList = queues.filter(item => item['name'].toLowerCase().includes(filterValue.toLowerCase()));
        setFilteredQueues(filteredList);
    }

    useEffect(() => {
        const filteredList = queues.filter(item => item['name'].toLowerCase().includes(queueSearch.toLowerCase()));
        setFilteredQueues(filteredList);
    }, [queues])

    const requestSearch = (searchString) => {
        const resultList = agentList?.filter((row) => {
            return JSON.stringify(row).toLowerCase().includes(searchString.toLowerCase().trim());
        });
        setFilteredRows(resultList);
        setSearchValue(searchString);
        setPage(0);
    };

    const updateFlag = async (id, isFlagged) => {
        try {
            const list = agentList.map(item => {
                if (item.id === id) {
                    item.isFlagged = !isFlagged;
                }
                return item;
            });
            setFilteredRows(list);
            setAgentList(list);
            await updateFlagStatus(
                {
                    "conversations": [
                        {
                            "conversationId": id,
                            "isFlagged": !isFlagged + ""
                        }
                    ],
                    "userId": getStorageItem('userId', true, sessionStorage)
                });

        } catch (e) {
            console.error(e);
            clearInterval(apiInterval);
            list = agentList.map(item => {
                if (item.id === id) {
                    item.isFlagged = isFlagged;
                }
                return item;
            });
            setFilteredRows(list);
            setAgentList(list);
            if (e.message === InvalidToken) {
                navigate('/login');
            }
        }
    }

    const onAllCheckBoxClick = (event) => {
        if (event.target.checked) {

            const newSelected = filteredRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item) => {
                return {
                    id: item.id,
                    isFlagged: item.isFlagged
                };
            });


            setSelectedConversations(newSelected);
            return;
        }
        setSelectedConversations([]);
    };

    const OnCheckBoxClick = (event, id, flagStatus) => {
        const selectedIndex = selectedConversations.findIndex(item => item.id == id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedConversations, {
                id: id,
                isFlagged: flagStatus
            });
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedConversations.slice(1));
        } else if (selectedIndex === selectedConversations.length - 1) {
            newSelected = newSelected.concat(selectedConversations.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedConversations.slice(0, selectedIndex),
                selectedConversations.slice(selectedIndex + 1),
            );
        }

        setSelectedConversations(newSelected);
    };

    const isSelected = (id) => {
        return selectedConversations.findIndex(item => item.id == id) !== -1;
    }

    const updateMultipleFlags = async () => {
        try {
            setIsLoading(true);
            await updateFlagStatus({
                "conversations": selectedConversations.map(item => ({
                    "conversationId": item.id,
                    "isFlagged": isFlagAll + ""
                })),
                "userId": getStorageItem('userId', true, sessionStorage)
            });
            const list = agentList.map(item => {
                if (selectedConversations.findIndex(conv => conv.id == item.id) !== -1) {
                    item.isFlagged = isFlagAll;
                }
                return item;
            });
            setIsLoading(false);
            setFilteredRows(list);
            setAgentList(list);
            setSelectedConversations([]);
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            if (e.message === InvalidToken) {
                navigate('/login');
            }
        }
    }

    

    return (
        <Box sx={{ display: "flex" }} id="rtt-supervisor-page">
            {openPopup && <TranscriptModal open={openPopup} onClose={onClosePopup} conversation={openedConversation} updateFlag={updateFlag}></TranscriptModal>}
            <Paper sx={{ width: 320, marginRight: 2, marginTop: 6.5 }}>
                <QueueContainer sx={{
                    minHeight: 700, maxHeight: 1000, height: 700, overflowX: 'hidden', borderRadius: '5px',

                }}>
                    <Table aria-label="customized table" stickyHeader >
                        <TableHead>
                            <TableRow>
                                {!isQueueFilter ?
                                    <StyledTableCell>Queues <FilterAltIcon sx={{ cursor: 'pointer', float: 'right' }} onClick={() => { setQueueFilter(!isQueueFilter) }} /></StyledTableCell > :
                                    <StyledTableCell>
                                        <Box sx={{ display: "flex", width: '100%' }}>
                                            <Input type='text' placeholder='Queues' sx={{ width: "100%", fontSize: 14 }} onChange={(eve) => { queueFilter(eve.target.value) }} />
                                            <CloseIcon sx={{ cursor: 'pointer', marginLeft: -1 }} onClick={() => { setQueueFilter(!isQueueFilter); queueFilter(''); }} />
                                        </Box>
                                    </StyledTableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredQueues.map((row, index) => {
                                return (
                                    <TableRowQueue key={index} onClick={() => setSelectedQueue({ id: row.id, name: row.name, divisionId: row.division.id })} className={`${selectedQueue?.id === row.id ? 'activeQueue' : ''}`}>
                                        <StyledTableCell scope="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>{row.name}</span>
                                            <span className={'live-count ' + ` ${row?.negScoreCnt > 0 ? 'negativeCount' : ''}`}>{row?.liveCount ?? 0}</span>
                                        </StyledTableCell>
                                    </TableRowQueue>
                                )
                            })}
                        </TableBody>
                    </Table>
                </QueueContainer>
            </Paper>

            {isLoading && <Box sx={{ height: '85vh', width: '100%' }}><Spinner></Spinner></Box>}
            {!isLoading && <Box sx={{ width: '100%', overflow: 'hidden' }}>
                <div className='table-details'>
                    {selectedQueue && <h4 className='selected-Queue'>Queue Name: {selectedQueue.name}</h4>}
                    {!selectedQueue && <h4 className='selected-Queue'>No Queue Selected</h4>}
                    {selectedConversations.length !== 0 && <StyledButton sx={{ margin: '0 0.5rem 0 auto' }} onClick={updateMultipleFlags}>{isFlagAll ? 'Flag Selected' : 'UnFlag Selected'}</StyledButton>}
                    <StyledSearch id="outlined-basic" label="Search" variant="outlined" value={searchValue}
                        onChange={(e) => requestSearch(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                    />
                </div>



                <Paper sx={{ overflow: 'hidden' }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 700, borderRadius: '5px' }} aria-label="customized table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align='center'>
                                        <Checkbox
                                            color="primary"
                                            indeterminate={selectedConversations.length > 0 && selectedConversations.length < filteredRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.length}
                                            checked={filteredRows?.length > 0 && selectedConversations.length === filteredRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.length}
                                            onChange={onAllCheckBoxClick}
                                            inputProps={{
                                                'aria-label': 'select all Conversations',
                                            }}
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                    color: 'black',
                                                },
                                                padding: 0
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">Agents</StyledTableCell>
                                    <StyledTableCell align="center">Caller's Number</StyledTableCell>
                                    {/* <StyledTableCell align="center">ANI</StyledTableCell> */}
                                    <StyledTableCell align="center">Start Time</StyledTableCell>
                                    <StyledTableCell align="center">Direction</StyledTableCell>
                                    <StyledTableCell align="center">Sentiment</StyledTableCell>
                                    <StyledTableCell align="center">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows?.length > 0 ? filteredRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const isItemSelected = isSelected(row.id);

                                        return (<StyledTableRow key={row.id}>
                                            <StyledTableCell align="center" ><Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                onChange={(event) => OnCheckBoxClick(event, row.id, row.isFlagged)}
                                                sx={{
                                                    color: '#34435e',
                                                    '&.Mui-checked': {
                                                        color: '#34435e',
                                                    },
                                                }}
                                            /></StyledTableCell>
                                            <StyledTableCell align="center" >{row.agentsName}</StyledTableCell>
                                            <StyledTableCell align="center">{row.callDirection == 'inbound' ? row.ani :row.dnis}</StyledTableCell>
                                            {/* <StyledTableCell align="center">{row.ani}</StyledTableCell> */}
                                            <StyledTableCell align="center">{formatDateAndTime(row.startTime)}</StyledTableCell>
                                            <StyledTableCell align="center">{row.callDirection}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Box sx={{ display: 'flex', justifyContent: 'center'}}> {getSentimentIcon(row.analysisScore)}</Box>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                <div className='rttSupervisorActions'>
                                                    {!row.isFlagged && <Tooltip title="Not Flagged"> <FlagOutlinedIcon sx={{ color: '#23395d', cursor: 'pointer' }} onClick={() => { updateFlag(row.id, row.isFlagged) }} /></Tooltip>}
                                                    {row.isFlagged && <Tooltip title="Flagged"> <FlagIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={() => { updateFlag(row.id, row.isFlagged) }} /></Tooltip>}
                                                    <Tooltip title="Transcript"> <MessageIcon sx={{ color: '#23395d', cursor: 'pointer' }} onClick={() => { onOpenPopup(row) }} /></Tooltip>
                                                    <Tooltip title="Open in GC"> <OpenInNewIcon sx={{ color: '#23395d', cursor: 'pointer' }} onClick={() => { openInGenesysCloud(row.id) }} /></Tooltip>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>)
                                    }) :
                                    <StyledTableRow><StyledTableCell colSpan={9}>
                                        {selectedQueue && <Typography sx={{ textAlign: 'center' }}>There are no live conversations for this queue</Typography>}
                                        {!selectedQueue && <Typography sx={{ textAlign: 'center' }}>No Queue is seleceted</Typography>}
                                    </StyledTableCell></StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Divider />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredRows?.length ? filteredRows.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>}
        </Box>
    );
}

export default SupervisourView;
