import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Chat } from 'components/chat';
import { useEffect, useState } from 'react';
import { getConversationMessages, getSignedUrl } from 'services/purecloud';
import './transcriptModal.css';
import { StyledButton } from 'common/common';
import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router';
import { InvalidToken } from 'common/Constants';
import {  getSentimentIcon } from 'services/utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: 0,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paperScrollPaper': {
        height: '85vh',
        width: '30vw',
        minWidth: '450px'
    }
}));

const TranscriptModal = (props) => {
    const [messages, setMessages] = useState([]);
    const [analysisScore, setAnalysisScore] = useState(null);
    const [prevAnalysisScore, setPrevAnalysisScore] = useState(null);
    const [isFlagged, setIsFlagged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isScrollEnabled, setScrollEnabled] = useState(true);

    const navigate = useNavigate();


    useEffect(() => {
        let intervalId = '';
        let signedUrl = '';
        try {
            setIsFlagged(props.conversation.isFlagged);
            async function getMessages() {
                const response = await getSignedUrl(props.conversation.conversationId);
                signedUrl = response.signedUrl;
                await getConversation();
            }
            async function getConversation() {
                const messages = await getConversationMessages(signedUrl)
                setAnalysisScore(messages?.analysisScore);
                setPrevAnalysisScore(messages?.prevAnalysisScore)
                setMessages(messages.messages);
                setIsLoading(false);
            }
            setIsLoading(true);
            getMessages();


            if (!props.isFlaggedView) {
                intervalId = setInterval(getConversation, 30 * 1000);
            }
        } catch (e) {
            console.error(e);
            if (e.message === InvalidToken) {
                navigate('/login');
            }
        }
        return () => {
            clearInterval(intervalId);
        }
    }, []);


    const scrollToBottom = () => {
        const elementToScroll = document.getElementById('rtt-chat-box-agent-view');
        if (elementToScroll) {
            elementToScroll.scrollTop = elementToScroll.scrollHeight;
        }
    }

    const handleClose = () => {
        props.onClose(false);
        setMessages([]);
        setAnalysisScore(null);
    };

    const updateFlagStatus = async () => {
        setIsFlagged(!isFlagged);
        await props.updateFlag(props.conversation.conversationId, isFlagged);
    }

    const toggleScroll = () => {
        setScrollEnabled(!isScrollEnabled);
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <Box sx={{
                    m: 0, p: 2, display: 'flex', alignItems: 'center', backgroundColor: '#34435e', fontWeight: '800',
                    color: 'white'
                }} id="customized-dialog-title">
                    {!props.isFlaggedView && <h4 className='transcriptMdlTitle'>Real-Time Transcript</h4>}
                    {props.isFlaggedView && <h4 className='transcriptMdlTitle'>Agent Transcript</h4>}
                    {!isLoading && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {getSentimentIcon(analysisScore)}
                        {!props.isFlaggedView && <>
                            {prevAnalysisScore <= analysisScore && <ArrowUpwardIcon sx={{ color: 'green' }} />}
                            {prevAnalysisScore > analysisScore && <ArrowDownwardIcon sx={{ color: 'red' }} />}
                        </>}
                    </Box>}
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers >
                    {isLoading && <Spinner />}
                    {!isLoading && messages && <Chat isScrollEnabled={isScrollEnabled} messages={messages}></Chat>}
                </DialogContent>
                <DialogActions>
                    {props.isFlaggedView && <StyledButton onClick={scrollToBottom}>
                        Scroll To Bottom
                    </StyledButton>}
                    {!props.isFlaggedView && <StyledButton onClick={() => updateFlagStatus()}>
                        {isFlagged ? 'Flagged' : 'Flag'}
                    </StyledButton>}
                    {!props.isFlaggedView && <StyledButton onClick={toggleScroll}> {isScrollEnabled ? 'Disable' : 'Enable'} AutoScroll</StyledButton>}

                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default TranscriptModal;
