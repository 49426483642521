import { useEffect, useState } from 'react';
import './agent.css'
import { Chat } from 'components/chat';

import { useSelector, useDispatch } from "react-redux";

const platformClient = window.require('platformClient');
import { addConversationId, createConversationInterval } from 'redux/agent/agentSlice';
import WebSocketRTT from 'components/WebSocket/WebSocketRTT';
import { addConversationDetails, storeMessagesToCloud } from 'redux/agent/agentActions';
import { getParameterByName, getSentimentIcon, } from 'services/utils';

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import { Box, Tooltip } from '@mui/material';
import { StyledButton } from 'common/common';
import config, { agentRole } from '../../config';
import { getStorageItem, setStorageItem } from 'services/applicationStorage';
import { useNavigate } from 'react-router';
import { InvalidToken } from 'common/Constants';


const Agent = () => {
    const messages = useSelector((state) => state.agent.messages);
    const analysisScore = useSelector((state) => state.agent.analysisScore);
    const prevAnalysisScore = useSelector((state) => state.agent.prevAnalysisScore);
    const error = useSelector((state) => state.agent.error);
    const [isScrollEnabled, setScrollEnabled] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let intervalId;
        let intervalId2;

        const purecloudInitialization = async () => {
            const searchParams = new URLSearchParams(location.search);
            let env = searchParams.get('environment');
            let conversationId = searchParams.get('conversationId');
            let usePopupAuth = searchParams.get('usePopupAuth');
            if (!usePopupAuth) {
                usePopupAuth = false;
            }
            setStorageItem('usePopupAuth', usePopupAuth, true, sessionStorage);
            const token = localStorage.getItem('purecloud-csp-token');
            if (token) {
                setStorageItem('purecloud-csp-token', token, true, sessionStorage);
            }

            if (conversationId != null) {
                sessionStorage.setItem('agent-current-interaction', conversationId)

            }

            if (!env) {
                let sessionEnv = getStorageItem('purecloud-csp-env', true, sessionStorage);
                if (sessionEnv) {
                    env = sessionEnv;
                } else {
                    env = config.defaultPcEnv;
                }
            }

            setStorageItem('purecloud-csp-env', env, true, sessionStorage);
            try {
                if (getParameterByName('access_token')) {
                    setStorageItem('purecloud-csp-token', getParameterByName('access_token'), true, sessionStorage);
                }

                const envToken = getStorageItem('purecloud-csp-token', true, sessionStorage);


                // const isAuthorized = await authorizeTheToken(envToken, env);
                // if (!isAuthorized) {
                //     // window.location.replace('#!/login');
                //     navigate('/login?redirectUrl=agentView&usePopupAuth=' + usePopupAuth);
                //     return;
                // }
                const isCheckNeeded = JSON.parse(localStorage.getItem('isCheckNeeded'));
                if (isCheckNeeded) {
                    let apiInstance = new platformClient.AuthorizationApi();
                    platformClient.ApiClient.instance.setAccessToken(envToken);
                    platformClient.ApiClient.instance.setEnvironment(getStorageItem('purecloud-csp-env', true, sessionStorage));

                    const data = await apiInstance.getAuthorizationSubjectsMe();
                    localStorage.setItem('isCheckNeeded', false);
                    if (data) {
                        let hasAccess = false;
                        data.grants.filter(x => {
                            if (x.role.name === agentRole) {
                                hasAccess = true;
                            }
                        })
                        setStorageItem('userId', data.id, true, sessionStorage);
                        if (!hasAccess) {
                            navigate('/unauthorized');
                            return;
                        }
                    } else {
                        navigate('/unauthorized');
                        return;
                    }
                }

                setIsLoggedIn(true);
                const storeToCloudStorage = async () => {
                    try {
                        dispatch(storeMessagesToCloud());

                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };

                const addConversation = () => {
                    dispatch(addConversationId({
                        conversationId: getStorageItem('agent-current-interaction', true, sessionStorage)
                    }))
                    dispatch(addConversationDetails())
                }

                addConversation();
                intervalId = setInterval(storeToCloudStorage, 10000);
                intervalId2 = setInterval(addConversation, 5000);
                dispatch(createConversationInterval({ interval: intervalId2 }));

                return;
            } catch (error) {
                console.error(error);
                if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
                    sessionStorage.setItem('conversationId', conversationId)
                    navigate('/login?redirectUrl=agentView');
                }
            }
        }

        if (error?.status == 401) {
            navigate('/login?redirectUrl=agentView');
        }

        purecloudInitialization();

        // Clean up the interval on unmount
        return () => {
            clearInterval(intervalId2);
            clearInterval(intervalId)
        };
    }, []);

    const toggleScroll = () => {
        setScrollEnabled(!isScrollEnabled);
    }

    useEffect(() => {
        if (error?.status == 401) {
            navigate('/login?redirectUrl=agentView');
        }
    }, [error])

    const calculateIndicator = () => {
        let indicator = '';
        if(messages[messages.length -1]?.sentimentScore < 0) {
            indicator = 'declining';
        } else {
            indicator = 'improving';
        }

        if(indicator == 'improving') {
            return <Box sx={{ backgroundColor: '#3C8527', marginLeft: '5px', borderRadius: '8px', padding:'5px', fontSize: '12px', display: 'flex', alignItems:'center', justifyContent: 'space-around' }}><KeyboardDoubleArrowUpIcon sx={{ color: 'white',fontSize: '12px'}} size='small'/> improving</Box>;
        } else {
            return <Box sx={{ backgroundColor: '#EA0B0B', marginLeft: '5px', borderRadius: '8px', padding:'5px', fontSize: '12px' , display: 'flex', alignItems:'center', justifyContent: 'space-around'}}><KeyboardDoubleArrowDownIcon sx={{ color: 'white',fontSize: '12px'}} size='small'/> declining</Box>
        }

    }

    return (
        <>
            {isLoggedIn && <WebSocketRTT></WebSocketRTT>}
            <div className='agentChatView'>
                <div className='agentViewTitle'>
                    <h4 >Real-Time Agent Transcript</h4>
                    {/* {<Tooltip title={'Overall sentiment is positive'} placement="right" arrow><Box sx={{ backgroundColor: '#3C8527', borderRadius: '50%', marginLeft: '5px', padding: '2px 5px 5px 5px' }}>
                        <ThumbUpAltIcon sx={{ color: 'white' }} fontSize='small'></ThumbUpAltIcon>
                    </Box> </Tooltip>} */}
                    {/* <Tooltip title={'Overall sentiment is negative'} placement="right"  arrow><Box sx={{ backgroundColor: '#EA0B0B', borderRadius: '50%',marginLeft: '5px', padding:'2px 5px 5px 5px' }}>
                            <ThumbDownOffAltIcon sx={{ color: 'white'}}/>
                        </Box> </Tooltip> */}
                    {/* <Tooltip title={'Overall sentiment is neutral'} placement="right"  arrow><RemoveCircleIcon sx={{ marginLeft:'10px'}}/></Tooltip> */}
                    {/* <Box sx={{ backgroundColor: '#3C8527', marginLeft: '5px', borderRadius: '8px', padding:'5px' }}><KeyboardDoubleArrowUpIcon sx={{ color: 'white'}}/> improving</Box> */}
                    {/* <Box sx={{ backgroundColor: '#EA0B0B', marginLeft: '5px', borderRadius: '8px', padding:'5px' }}><KeyboardDoubleArrowDownIcon sx={{ color: 'white'}}/> declining</Box> */}

                    {/* <ArrowUpwardIcon sx={{ color: 'green' }} /> */}

                    {messages && messages.length !== 0 && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {getSentimentIcon(analysisScore)}
                        {calculateIndicator()}
                        {/* {messages[messages.length-1] < 0 || messages[messages.length-1] < messages[messages.length-2] && <Box sx={{ backgroundColor: '#3C8527', marginLeft: '5px', borderRadius: '8px', padding:'5px', fontSize: '12px', display: 'flex', alignItems:'center', justifyContent: 'space-around' }}><KeyboardDoubleArrowUpIcon sx={{ color: 'white',fontSize: '12px'}} size='small'/> improving</Box>}
                        {prevAnalysisScore > analysisScore && <Box sx={{ backgroundColor: '#EA0B0B', marginLeft: '5px', borderRadius: '8px', padding:'5px', fontSize: '12px' , display: 'flex', alignItems:'center', justifyContent: 'space-around'}}><KeyboardDoubleArrowDownIcon sx={{ color: 'white',fontSize: '12px'}} size='small'/> declining</Box>} */}
                    </Box>}
                </div>
                <div className="agentviewContent" id="rtt-agentview-content">
                    <Chat isScrollEnabled={isScrollEnabled} messages={messages}></Chat>
                </div>
                <Box className='agentViewButton'>
                    <StyledButton onClick={toggleScroll}> {isScrollEnabled ? 'Disable' : 'Enable'} AutoScroll</StyledButton>
                </Box>
            </div>
        </>
    );
};

export default Agent;
