import config, { adminRole, agentRole, supervisorRole } from '../config';
import React, { useEffect, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Util
import { authorizeTheToken, checkIsJSON, getParameterByName, setAdminRole, updateUserId, userId } from '../services/utils';
import { setStorageItem, getStorageItem, removeStorageItem } from 'services/applicationStorage';

// Services
import { getQueuesDivision } from 'services/purecloud';
import { Box } from '@mui/material';
import Spinner from './Spinner/Spinner';
import { InvalidToken } from 'common/Constants';

const platformClient = window.require('platformClient');


const Main = ({ }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const purecloudInitialization = async () => {
            const searchParams = new URLSearchParams(location.search);
            
            let usePopupAuth = searchParams.get('usePopupAuth');
            if (!usePopupAuth) {
                usePopupAuth = false;
            }
            setStorageItem('usePopupAuth', usePopupAuth, true, sessionStorage);

            let env = searchParams.get('environment');
            if (!env) {
                let sessionEnv = getStorageItem('purecloud-csp-env', true, sessionStorage);
                if(sessionEnv){
                    env = sessionEnv;
                } else {
                    env = config.defaultPcEnv;
                }      
            }
            setStorageItem('purecloud-csp-env', env, true, sessionStorage);
            console.log('Main.purecloud.init:', env);

            if (getParameterByName('access_token')) {
                setStorageItem('purecloud-csp-token', getParameterByName('access_token'), true, sessionStorage);
            }
            const token = localStorage.getItem('purecloud-csp-token');
            if(token) {
                setStorageItem('purecloud-csp-token', token, true, sessionStorage);
            }

            const envFromSession = getStorageItem('purecloud-csp-env', true, sessionStorage);
            const envFromSessionSanitized = checkIsJSON(envFromSession?.trim()) ? JSON.parse(envFromSession?.trim()) : envFromSession?.trim();
           
            const envToken = getStorageItem('purecloud-csp-token', true, sessionStorage);
            const envTokenSanitized = typeof (envToken) === 'string' && envToken.trim().length > 0 ? envToken.trim() : '';
            let hasDashboardAccess = false;
            let hasAccess = false;
           
            try {
                const isAuthorized = await authorizeTheToken(envToken, env);
                if (!isAuthorized) {
                    // window.location.replace('#!/login');
                    navigate('/login');
                    return;
                }
                // const organization = await getOrganization(envFromSessionSanitized, envTokenSanitized);

                //Get Divisions based on the grant role.
                let apiInstance = new platformClient.AuthorizationApi();
                platformClient.ApiClient.instance.setAccessToken(envToken);
                const data = await apiInstance.getAuthorizationSubjectsMe();

                if (data) {
                    let divisions = new Set();

                    data.grants.filter(x => {
                        if (x.role.name === supervisorRole) {
                            hasDashboardAccess = true;
                            divisions.add(x.division.id);
                            if (x.role.name === adminRole) {
                                setAdminRole();
                            }
                            hasAccess = true;
                        }
                    })
                    // addDivisons(Array.from(divisions));

                    setStorageItem('userId', data.id, true, sessionStorage);
                    if (hasDashboardAccess) {
                        await getQueuesDivision(Array.from(divisions), envToken, env)
                        navigate('/home/agents');
                    } else {
                        navigate('/unauthorized');
                    }


                }
            } catch (error) {
                if (error?.message === 'This method is not supported in a browser.' || (error.status === 401 && error.code === 'bad.credentials') || error.message === InvalidToken) {
                    //handle expired token
                    removeStorageItem('purecloud-csp-token', true, sessionStorage);
                    console.log('Invalid token - Redirecting to login page');
                    navigate('/login')
                } else {
                    console.error(error);
                    throw error;
                }
            }
        };

        purecloudInitialization()
        // eslint-disable-next-line
    }, [])

    return (<Box sx={{ height: '100vh' }}><Spinner /></Box>)
}

export default Main
